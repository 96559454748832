<template>
    <div class="max">
        <div class="spinner" v-show="formLoading">
            <div class="d-flex justify-content-center">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
        </div>
        <b-row v-show="!formLoading">
            <b-col cols="12" md="7">
                <ValidationObserver ref="formModalValidate">
                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group :label="$t('period')">
                                <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                                    <semesters-selectbox v-model="formData.semester_id"
                                        :validate-error="errors[0]"></semesters-selectbox>
                                </ValidationProvider>
                            </b-form-group>
                            <b-form-group :label="$t('course')" class="mb-1">
                                <ValidationProvider name="course_id" rules="required" v-slot="{ errors }">
                                    <course-auto-complete v-model="formData.course_id"
                                        :valueObject="{ value: section.course_id, text: section.course_code + ' - ' + section.course_name }"
                                        :validate-error="errors[0]" />
                                </ValidationProvider>
                            </b-form-group>
                            <div class="border rounded p-3 bg-light" v-if="courseCredits.credit != null">
                                <div class="row">
                                    <div class="col-6">{{ $t('theoretical') }}: </div>
                                    <div class="col-6">{{ courseCredits.teoric }}</div>
                                    <div class="col-6">{{ $t('practical') }}: </div>
                                    <div class="col-6">{{ courseCredits.pratic }}</div>
                                    <div class="col-6">{{ $t('credit') }}: </div>
                                    <div class="col-6">{{ courseCredits.credit }}</div>
                                    <div class="col-6">{{ $t('ects_credit') }}: </div>
                                    <div class="col-6">{{ courseCredits.ects }}</div>
                                </div>
                            </div>
                            <b-form-group :label="$t('status')">
                                <ValidationProvider name="section" rules="required" v-slot="{ errors }">
                                    <status-selectbox v-model="formData.status"
                                        :validate-error="errors[0]"></status-selectbox>
                                </ValidationProvider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="8">
                            <label>{{ $t('section_information') }}</label>
                            <div class="border p-4 rounded-sm">
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('section_type')">
                                            <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                                                <section-type v-model="formData.type" @changedValue="setSectionMinMax"
                                                    :validate-error="errors[0]"></section-type>
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group :label="$t('section')">
                                            <ValidationProvider name="section" rules="required" v-slot="{ errors }">
                                                <multiselect v-model="formData.section" :options="sectionOptions"
                                                    :class="errors[0] ? 'box-border-color' : ''" label="text"
                                                    track-by="value" :multiple="false" :select-label="''"
                                                    :selected-label="''" :deselect-label="''" :placeholder="$t('select')"
                                                    :searchable="true" :disabled="formData.type ? false : true">
                                                </multiselect>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('teaching_staff')">
                                            <ValidationProvider name="instructor_id" rules="required" v-slot="{ errors }">
                                                <staff-auto-complete v-model="formData.instructor_id"
                                                    :validate-error="errors[0]"
                                                    :valueObject="{ value: section.instructor_id, 'text': section.instructor_name }" />
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group :label="$t('quota')">
                                            <ValidationProvider name="quota" rules="required" v-slot="{ errors }">
                                                <b-form-input v-model="formData.quota" type="number" min="0" max="9999"
                                                    v-check-min-max-value :state="errors[0] ? false : null">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                    class="mb-2"></b-form-invalid-feedback>
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('language')">
                                            <ValidationProvider name="language" rules="required" v-slot="{ errors }">
                                                <parameter-selectbox code="languages" v-model="formData.language"
                                                    :validate-error="errors[0]"></parameter-selectbox>
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group :label="$t('campus')">
                                            <ValidationProvider name="campus_id" rules="required" v-slot="{ errors }">
                                                <campus-selectbox v-model="formData.campus_id" :validateError="errors[0]"
                                                    :disabled="courseWork.type == 'synchronous' || courseWork.type == 'asynchronous' ? true : false">
                                                </campus-selectbox>
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="!makeupExamStatus">
                                    <b-col>
                                        <b-form-group :label="$t('makeup')">
                                            <b-form-select :options="getYesNoSelectboxOptions()"
                                                v-model="formData.has_make_up_exam">
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <b-form-group :label="$t('final')">
                                            <b-form-select :options="getYesNoSelectboxOptions()"
                                                v-model="formData.has_final_exam">
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-form-group :label="$t('explanation')">
                                    <ValidationProvider name="explanation" rules="">
                                        <b-form-textarea v-model="formData.explanation" rows="1"></b-form-textarea>
                                    </ValidationProvider>
                                </b-form-group>

                                <b-row v-if="course && course.type == 'P'">
                                    <b-col cols="12">
                                        <b-form-group :label="$t('section_definition')">
                                            <ValidationProvider name="definition" rules="" v-slot="{ errors }">
                                                <b-input-group class="mb-1" prepend="TR">
                                                    <b-form-input v-model="formData.definition"
                                                        :state="errors[0] ? false : null" />
                                                </b-input-group>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2" />
                                            </ValidationProvider>
                                            <ValidationProvider name="definition_en" v-slot="{ errors }">
                                                <b-input-group prepend="EN">
                                                    <b-form-input v-model="formData.definition_en"
                                                        :state="errors[0] ? false : null" />
                                                </b-input-group>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                            </ValidationProvider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </b-col>
            <b-col cols="12" md="5">
                <label>{{ $t('processing_information') }}</label>
                <div class="border p-4 rounded-sm">
                    <ValidationObserver ref="courseDetailValidate">
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t('processing_model')">
                                    <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                                        <b-form-select v-model="courseWork.type"
                                            :options="formData.campus_id == 5 ? courseWorkTypeOptions2 : courseWorkTypeOptions"
                                            :disabled="formData.type ? false : true"></b-form-select>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                            class="mb-2"></b-form-invalid-feedback>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group :label="$t('lesson_time')">
                                    <ValidationProvider name="hour" rules="required" v-slot="{ errors }">
                                        <b-form-input v-model="courseWork.hour" type="number" min="1" max="10"
                                            v-check-min-max-value :disabled="formData.type ? false : true"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                            class="mb-2"></b-form-invalid-feedback>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group :label="$t('classroom')">
                                    <ValidationProvider name="classroom_type" rules="required" v-slot="{ errors }">
                                        <parameter-selectbox code="classroom_types" v-model="courseWork.classroom_type"
                                            :disabled="formData.type ? formData.campus_id == 5 ? true : false : true"></parameter-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                            class="mb-2"></b-form-invalid-feedback>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <label>&nbsp;</label>
                                <b-button variant="primary" @click="addCourseWorks" block
                                    :disabled="formData.type ? false : true">{{ $t('add') }}</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="7" v-if="courseWork.classroom_type == 'lab'">
                                <b-form-group :label="$t('lab_credit')">
                                    <ValidationProvider name="lab"
                                        :rules="courseWork.classroom_type == 'lab' ? 'required' : ''" v-slot="{ errors }">
                                        <classroom-selectbox classroomType="lab" valueType="name"
                                            v-model="courseWork.classroom"
                                            :disabled="formData.type ? false : true"></classroom-selectbox>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                            class="mb-2"></b-form-invalid-feedback>
                                    </ValidationProvider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                    <b-table bordered striped hover :fields="courseWorkTableFields" :items="courseWorks"
                        v-if="courseWorks && courseWorks.length">
                        <template #cell(type)="data">
                            {{ $t(data.value) }}
                        </template>
                        <template #cell(hour)="data">
                            {{ data.value }} {{ $t('hour') }}
                        </template>
                        <template #cell(classroom_type)="data">
                            <span>{{ $t(data.value) }}</span>
                            <span v-if="data.item.classroom">-{{ $t(data.item.classroom) }}</span>
                        </template>
                        <template #cell(delete)="data">
                            <div class="d-flex">
                                <b-button variant="danger" size="sm" @click="removeCourseWorks(data.index)">
                                    <i class="ri-close-line"></i>
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-col>
            <b-col cols="12">
                <div class="d-flex justify-content-center mt-3">
                    <b-button variant="primary" @click="updateForm" :disabled="formProcess">{{ $t('save') }}
                    </b-button>
                </div>
            </b-col>
        </b-row>

    </div>
</template>

<script>
// Components
import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';
import SectionType from '@/components/interactive-fields/SectionType';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';
import StatusSelectbox from '@/components/interactive-fields/StatusSelectbox';
import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox';
import LanguageSelectbox from '@/components/interactive-fields/LanguageSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import ClassroomSelectbox from '@/components/interactive-fields/ClassroomSelectbox'

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from "vuex"
import setSelected from '@/helpers/setSelected'

// Services
import SectionService from '@/services/SectionService'
import CourseService from '@/services/CourseService';

export default {
    name: "SectionsUpdateForm",
    components: {
        CourseAutoComplete,
        SectionType,
        SemestersSelectbox,
        StatusSelectbox,
        StaffAutoComplete,
        CampusSelectbox,
        LanguageSelectbox,
        ParameterSelectbox,
        ClassroomSelectbox,
        ValidationProvider,
        ValidationObserver
    },
    props: {
        formId: {
            type: Number
        }
    },
    computed: {
        ...mapGetters(['auth/getActiveRole']),
        makeupExamStatus() {
            return ["FS", "FSY", "ES"].includes(this['auth/getActiveRole'])
        }
    },
    data() {
        return {
            formData: {
                semester_id: null,
                course_id: null,
                status: null,

                type: null,
                section: null,
                sectionSelected: null,
                instructor_id: null,
                quota: null,
                language: null,
                campus_id: null,
                explanation: null,
                definition: null,
                definition_en: null,
                has_make_up_exam: null,
                has_final_exam: null,
                theoretical_model: [],
                practical_model: []
            },
            section: {},
            courseWorks: [],
            courseWork: {
                type: null,
                hour: null,
                classroom_type: null
            },
            courseWorkTableFields: [
                {
                    key: 'type',
                    label: this.$t('processing_model').toUpper(),
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'hour',
                    label: this.$t('lesson_time').toUpper(),
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'classroom_type',
                    label: this.$t('classroom').toUpper(),
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'delete',
                    label: '',
                    thClass: 'text-center',
                    tdClass: 'text-center width-50'
                }
            ],
            courseWorkTypeOptions: [
                { value: null, text: this.$t('select') },
                { value: 'synchronous', text: this.$t('synchronous') },
                { value: 'asynchronous', text: this.$t('asynchronous') },
                { value: 'hybrid', text: this.$t('hybrid') },
                { value: 'in_class', text: this.$t('in_class') }
            ],
            courseWorkTypeOptions2: [
                { value: null, text: this.$t('select') },
                { value: 'synchronous', text: this.$t('synchronous') },
                { value: 'asynchronous', text: this.$t('asynchronous') },
                { value: 'hybrid', text: this.$t('hybrid') }
            ],
            formLoading: false,
            formProcess: false,
            sectionOptions: [],

            courseCredits: {
                teoric: null,
                pratic: null,
                credit: null,
                ects: null
            },
            course: null
        }
    },
    created() {
        if (this.formId) {
            this.getForm(this.formId);
        }
    },
    methods: {
        formDataClear() {
            this.formData = {
                semester_id: null,
                course_id: null
            }
            this.$refs.formModalValidate.reset();
        },

        setSectionMinMax() {
            this.formData.section = null
            this.sectionOptions = []

            if (this.formData.type == 1) {
                for (let i = 1; i <= 900; i++) {
                    this.sectionOptions.push({ value: i, text: i })
                }
            }
            else if (this.formData.type == 2) {
                for (let i = 901; i <= 999; i++) {
                    this.sectionOptions.push({ value: i, text: i })
                }
            }
            //this.getCourseCredits()
        },

        getCourseCredits() {
            if (this.formData.type != null && this.formData.course_id != null) {
                CourseService.get(this.formData.course_id)
                    .then(response => {
                        let data = response.data.data
                        if (this.formData.type == 1) {
                            this.courseWork.hour = Math.round(data.theoretical_credit)
                        }
                        else {
                            this.courseWork.hour = Math.round(data.practical_credit)
                        }
                    })
            }
            else {
                this.courseWork.hour = null
            }
        },

        getCourseCreditsForInfo(course_id) {
            if (course_id) {
                CourseService.get(course_id)
                    .then(response => {
                        let data = response.data.data
                        this.course = data;
                        this.courseCredits.teoric = data.theoretical_credit
                        this.courseCredits.pratic = data.practical_credit
                        this.courseCredits.credit = data.credit
                        this.courseCredits.ects = data.ects_credit
                        if (this.formData.type == 1) {
                            this.courseWork.hour = Math.round(data.theoretical_credit)
                        }
                        else {
                            this.courseWork.hour = Math.round(data.practical_credit)
                        }
                    })
            }
            else {
                this.courseCredits.credit = null
            }
        },

        // Create
        async addCourseWorks() {
            const isValid = await this.$refs.courseDetailValidate.validate();
            if (isValid && this.formData.type) {
                this.courseWorks.push({
                    ...this.courseWork
                })
            }
        },
        removeCourseWorks(index) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.courseWorks.splice(index, 1);
                    }
                });
        },
        setSelected: setSelected,
        getForm(id) {
            this.formLoading = true
            SectionService.get(id)
                .then(response => {
                    this.section = response.data.data;
                    this.formData.semester_id = this.section.semester_id;
                    this.formData.course_id = this.section.course_id;
                    this.formData.status = this.section.status;

                    this.formData.type = this.section.type;
                    
                    this.setSectionMinMax();
                    this.formData.instructor_id = this.section.instructor_id;
                    this.formData.quota = this.section.quota
                    this.formData.language = this.section.language
                    this.formData.campus_id = this.section.campus_id
                    this.formData.explanation = this.section.explanation
                    this.formData.definition = this.section.definition
                    this.formData.definition_en = this.section.definition_en
                    this.formData.has_make_up_exam = this.section.has_make_up_exam ? 1 : 0
                    this.formData.has_final_exam = this.section.has_final_exam ? 1 : 0
                    if (this.section.type == 1) {
                        this.courseWorks = this.section.theoretical_model;
                    }
                    else if (this.section.type == 2) {
                        this.courseWorks = this.section.practical_model;
                    }
                })
                .then(() => {
                    this.formLoading = false;
                    this.formData.section = { value: this.section.section, text: this.section.section }
                })
        },

        // Create
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formProcess = true;
                let formData = {
                    ...this.formData,
                    section: this.formData.section.value
                }

                if (this.courseWorks && this.courseWorks.length) {
                    this.courseWorks.forEach((item) => {
                        if (formData.type == 1) {
                            formData.theoretical_model = this.courseWorks;
                        }
                        else {
                            formData.practical_model = this.courseWorks;
                        }
                    })
                }

                SectionService.update(this.formId, formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$refs.formModalValidate.reset();
                        this.$emit('updateFormSuccess', true)
                    })
                    .catch(e => {
                        if (e.status == 422) {
                            if (e.data.errors.semester_id) this.$refs.formModalValidate.errors.semester_id.push(e.data.errors.semester_id[0]);
                            if (e.data.errors.section) this.$toast.error(e.data.errors.section[0]);
                        }
                        else if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                    .finally(() => {
                        this.formProcess = false;
                    })
            }
        }
    },
    watch: {
        'courseWork.type': {
            handler: function (val) {
                if (val == 'synchronous' || val == 'asynchronous') {
                    this.formData.campus_id = 5
                }
                else {
                    this.formData.campus_id = null
                }
            }
        },

        'formData.campus_id': {
            handler: function (val) {
                if (val == 5) {
                    this.courseWork.classroom_type = 'online'
                } else {
                    this.courseWork.classroom_type = null
                }
            }
        },

        'formData.course_id': {
            handler: function (val) {
                this.getCourseCreditsForInfo(val)
            }
        }
    }
}
</script>

